<template>
	<div class="container-fluid login_header">
		<div class="logo">
			<a href="#" class="cur_po_arrow"><img src="/images/login_logo.png" alt="" class="img-fluid"></a>
		</div>
		<span class="text-decoration-none" style="cursor: pointer" @click="openContactUsModel"><div class="contact_btn fs-18">Contact US</div></span>
	</div>
	<section class="bg-custom-color">
		<div class="container py-5 h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col-12 col-md-8 col-lg-6 col-xl-6">
					<div class="box">
						<div class="box-body p-20 custom-form">
							<h3 class="main_heading mb-0">Welcome Back</h3>
							<div class="sign_up_line fw-light py-2 mb-3 text-end fs-18"><span>New to Verismo?</span> <span @click="this.$router.push(`/sign-up`);" class="sign_link cursor-pointer">Sign Up</span></div>
							<div class="input_box mb-4" :class="{ error: v$.form.unique_id.$errors.length }">
								<input type="text" required id="typeunique_idX-2" class="form-control form-control-lg"
									v-model="form.unique_id" :class="{ 'is-invalid': errors && errors.unique_id }" autocomplete="off" @keyup.enter="submitForm" />
								<label class="form-label" for="typeunique_idX-2">Clinic ID</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form.unique_id.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.unique_id">
									{{ errors.unique_id[0] }}
								</div>
							</div>
							<div class="input_box mb-4" :class="{ error: v$.form.email.$errors.length }">
								<input type="text" required id="typeEmailX-2" class="form-control form-control-lg"
								v-model="form.email" :class="{ 'is-invalid': errors && errors.email }" autocomplete="off" @keyup.enter="submitForm"  />
								<label class="form-label" for="typeEmailX-2">Email</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form.email.$errors" :key="index">
									{{ error.$message }}
								</div>
								<div class="invalid-feedback" style="display:block;" v-if="errors && errors.email">
									{{ errors.email[0] }}
								</div>
							</div>
							<div class="input_box mb-4" :class="{ error: v$.form.password.$errors.length }">
								<input type="password" required id="typePasswordX-2" class="form-control form-control-lg"
								v-model="form.password" :class="{ 'is-invalid': errors && errors.password }" autocomplete="off" @keyup.enter="submitForm" />
								<label class="form-label" for="typePasswordX-2">Password</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form.password.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
							<div class="input_box mb-4" :class="{ error: v$.form.pin.$errors.length }">
								<input type="password" required id="typepinX-2" class="form-control form-control-lg"
								v-model="form.pin" :class="{ 'is-invalid': errors && errors.pin }" @keyup.enter="submitForm" />
								<label class="form-label" for="typepinX-2">Security Code</label>
								<div class="invalid-feedback" style="display:block;"
									v-for="(error, index) of v$.form.pin.$errors" :key="index">
									{{ error.$message }}
								</div>
							</div>
							<div class="text-end">
								<button class="comman_btn" @click="submitForm">
									Login
									<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
								</button>
							</div>
							<!-- <div class="fw-light" style="display: inline-block;"><a href="/LICENSE.txt" target="_blank">License</a></div> -->
							<!-- <div class="error-msg p-10" style="display: inline-block;">{{ backEndError }}</div> -->
						</div>
					</div>					
				</div>
			</div>
		</div>
	</section>

	<div class="modal fade" id="contact_us_modal" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl contact_create_modal">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
					<div class="box">
						<div>
							<h3 class="main_heading mb-40 l-h-n">Contact Us</h3>
							<p class="pt-15 pb-15 fs-16 l-h-n text-center fw-300 mb-35">We’re here to answer any question about our services. Please complete the following form to get connected directly.</p>
							<div class="connected-form-section">
								<div class="row">
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">First Name</label>
											<input type="email" class="form-control cursor-text">
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Last Name</label>
											<input type="email" class="form-control cursor-text">
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Email</label>
											<input type="email" class="form-control cursor-text">
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-6 col-lg-6">
										<div class="contact-form mb-20 l-h-n">
											<label class="form-label cursor-text mb-5p">Phone</label>
											<input type="email" class="form-control cursor-text">
										</div>
									</div>
									<div class="col-12 col-sm-12 col-md-12 col-lg-12">
										<div class="contact-form mb-40 l-h-n">
											<label class="form-label cursor-text mb-5p">How can we help you?</label>
											<textarea class="form-control" rows="3"></textarea>
										</div>
									</div>
								</div>
								<div class="btn_part text-center">
									<button class="comman_btn fs-18">Submit <img src="images/loader.gif" v-if="this.loader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import { mapActions, mapGetters } from 'vuex';

import {
	required,
	helpers,
	email
} from "@vuelidate/validators";
export default {
	name: "Login",
	setup() {
		return { v$: useVuelidate() };
	},
	components: {},
	data() {
		return {
			errors: null,
			form: {
				unique_id: null,
				email: null,
				password: null,
				pin: null,
			},
			loader: false,
			backEndError: ''
		};
	},
	validations() {
		return {
			form: {
				unique_id: {
					required: helpers.withMessage("Please enter Clinic ID.", required)					
				},
				email: {
					required: helpers.withMessage("Please enter Email.", required),
					email: helpers.withMessage("Please enter valid Email.", email)
				},
				password: {
					required: helpers.withMessage("Please enter Password.", required)					
				},
				pin: {
					required: helpers.withMessage("Please enter Pin.", required)					
				},
			},
		};
	},
	created() {
		this.$store.state.loader = true;
		setTimeout(() => {
			this.$store.state.loader = false;
		}, 500);
	},
	mounted() { },
	methods: {
		...mapActions('userInfo', ['updateAuthData']),
		openContactUsModel(){
			window.$("#contact_us_modal").modal("show")
		},
		submitForm() {
			this.v$.$validate();
			if (!this.v$.$error) {
				this.loader = true;
				// this.$store.state.loader = true;
				const form = this.form;
				axios.post("auth/login", form)
					.then((response) => {
						localStorage.setItem("authToken", response.data.data.token);
						localStorage.setItem("userId", response.data.data.user.id);
						localStorage.setItem("userName", response.data.data.user.username);
						// localStorage.setItem("email", response.data.data.user.email);
						localStorage.setItem("authUserName", response.data.data.user.full_name);
						localStorage.setItem("clinicId", response.data.data.user.clinic_id);
						localStorage.setItem("authUserRole", response.data.data.user.role);
						localStorage.setItem("preference", response.data.data.user.preference);
						localStorage.setItem("userFirstName", response.data.data.user.first_name);
						localStorage.setItem("userLastName", response.data.data.user.last_name);
						
						this.$store.state.userInfo = response.data.data.user
						// this.$store.dispatch('updateAuthData', response.data.data.user);
						this.updateAuthData(response.data.data.user);
						setTimeout(function () {
							location.reload(true);
						}, 100);
					})
					.catch((error) => {
						this.loader = false;
						// this.$store.state.loader = false;
						// this.backEndError = error.response.data.message;

						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else {
							this.$filters.moshaToast(error.response.data.message, "error");
						}
					})
			}
		},
	},
	computed:{
		...mapGetters('userInfo', ['getAuthData']),
	}
};
</script>
